import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client with your Supabase URL and API key
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const CandidateForm = ({ onNext }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [referal, setReferal] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.from('candidates').insert([{ name, email, phone, referal }]).select('*');
      console.log('Candidate registered successfully', name, email, phone, referal);
      if (error) {
        console.error('Error inserting data:', error.message);
        alert('Error registering candidate. Please try again.');
      } else if (data && data.length > 0) {
        onNext(data[0]);
      } else {
        console.error('Unexpected error: No data returned after insertion.');
        alert('Unexpected error. Please try again.');
      }
    } catch (error) {
      console.error('Error connecting to Supabase:', error.message);
      alert('Error connecting to the database. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Candidate Registration</h2>
        <p className="text-justify mb-4">
          Please register to start the test. Note that once the test starts, you must not change tabs or minimize the browser window. Doing so will result in the test being terminated immediately.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 p-2 block w-full border rounded"
              required
              placeholder='Your Full Name'
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-2 block w-full border rounded"
              required
              placeholder='Your Email ID'
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Phone:</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 p-2 block w-full border rounded"
              required
              placeholder='Your Phone Number'
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Do you have a reference who is presently working at Blue Water Trade Winds? If so please enter the name of the employee*. <br></br>(Not required) </label>
            <input
              type="text"
              value={referal}
              onChange={(e) => setReferal(e.target.value)}
              className="mt-1 p-2 block w-full border rounded"
              placeholder='Name of existing Blue Water Trade Wing Employee'
            />
          </div>
          <div className="flex justify-end">
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CandidateForm;
