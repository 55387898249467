import React from 'react';
import psychometricQuestionsFresher from './data/psychometricQuestionsFresher.json';
import englishLetterWriting from './data/englishLetterWriting.json';
import englishWriting from './data/englishWriting.json';

// import { Text, View, StyleSheet } from 'react-native';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    marginHorizontal: 20,
    marginVertical: 10,
    paddingTop: 5,
    paddingBottom: 30,
  },
  header: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 10,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    // left: 0,
    right: 50,
    textAlign: 'left',
    color: 'grey',
  },
  section: {
    margin: 10,
    padding: 5,
    // flexGrow: 1
  },
  columnContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  column: {
    flex: 1,
    marginHorizontal: 10,
    paddingHorizontal: 10,
    left: 0,
  },
  column1: {
    flex: 1,
    marginRight: 10, // Adds space between the columns
    fontSize: 14,
    marginVertical: 5,
    textDecoration: 'underline'
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: 10,
    color: '#323295',
    fontWeight: '900',
  },
  subTitle: {
    fontSize: 18,
    textAlign: 'center',
    margin: 10,
    color: '#fac900'
  },
  subTitle1: {
    fontSize: 16,
    textAlign: 'start',
    margin: 10,
    color: '#757575'
  },
  subTitle1a: {
    fontSize: 14,
    textAlign: 'start',
    marginTop: 4,
    color: '#898989'
  },
  subTitle2: {
    fontSize: 14,
    textAlign: 'right',
    marginRight: 60,
    color: '#000000',
    fontWeight: 'extrabold',
    
  },
  subTitle3: {
    fontSize: 14,
    textAlign: 'justify',
    marginRight: 60,
    color: '#000000',
    fontWeight: 'extrabold',
    marginTop: 10,
    marginBottom: 2,
  },
  text: {
    margin: 8,
    fontSize: 12,
    textAlign: 'left'
  },
  textsubheader: {
    marginBottom: 12,
    fontSize: 14,
    textAlign: 'center'
  },
  header: {
    fontSize: 16,
    marginBottom: 4,
    textAlign: 'center',
    color: 'grey',
  },
  viewer: {
    width: window.innerWidth, 
    height: window.innerHeight,
  },
  response: {
    marginTop: 2,
    marginRight: 40,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: '#757575',
    fontStyle: 'italic',
    fontSize: 14,
    lineHeight: 1.5,
  }
});

export const CandidatePDFDocument = ({ candidate, totalMarks, responses }) => {
    // Function to extract date and serial number
    const extractDetails = (id) => {
      const parts = id.split('_'); 
      const date = parts[2];       
      const serialNumber = parts[3]; 
      return { serialNumber, date };
    };
    const psychResponses = responses.filter(r => r.question_id.startsWith('Psych'));
    const letterResponses = responses.filter(r => r.question_id.startsWith('Eng_LetterWriti'));
    const compositionResponses = responses.filter(r => r.question_id.startsWith('Eng_Composition'));

    return (
      <>
        <PDFViewer style={styles.viewer}>
            <Document>
            <Page size="A4" style={styles.page}>
                <View fixed style={styles.columnContainer}>
                  <View style={styles.column}>
                    <Text style={styles.header}>{candidate.name}</Text>
                  </View>
                  <View style={styles.column}>
                  <Text style={styles.textsubheader}>Test Registration Number {extractDetails(candidate.id).serialNumber}</Text>
                  <Text style={styles.textsubheader}>Test taken on {extractDetails(candidate.id).date}</Text>
                  </View>
                  {/* <Text style={styles.textsubheader}>{extractDetails(candidate.id)}</Text> */}
                </View>
                <View style={styles.section}>
                  <Text style={styles.title}>Blue Water Trade Winds</Text>
                  <Text style={styles.subTitle}>Voyage Performance Analyst</Text>
                  <Text style={styles.header}>Online Test Report for {candidate.name}</Text>
                  {/* <Text style={styles.textsubheader}>{extractDetails(candidate.id)}</Text> */}
                
                <View style={styles.columnContainer}>
                  <View style={styles.column}>
                    <Text style={styles.text}>Candidate Name: {candidate.name}</Text>
                    <Text style={styles.text}>Candidate Email: {candidate.email}</Text>
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.text}>Candidate Phone: {candidate.phone}</Text>
                    <Text style={styles.text}>Referral Employee: {candidate.referal}</Text>
                  </View>
                </View>
                <Text style={styles.subTitle1}>Multiple Choice Questions Scorecard</Text>
                <View style={styles.columnContainer}>
                  <View style={styles.column}>
                    <Text style={styles.text}>Maths: </Text>
                    <Text style={styles.text}>Logical Reasoning:</Text>
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.text}>{totalMarks.Math}</Text>
                    <Text style={styles.text}>{totalMarks.Logical}</Text>
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.text}>Data Interpretation:</Text>
                    <Text style={styles.text}>English Grammer & Comprehension:</Text>
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.text}>{totalMarks.Data}</Text>
                    <Text style={styles.text}>{totalMarks.English}</Text>
                  </View>
                </View>
                  <Text style={styles.subTitle2}>Total marks scored :- {totalMarks.Total}</Text>
                  <Text style={styles.subTitle1}>English Writing Responses</Text>
                  <Text style={styles.subTitle1a}>Psychometric Questions</Text>
                    {psychResponses.map((response, index) => {
                      const question = psychometricQuestionsFresher.find(q => q.id === response.question_id);
                      return (
                        <View key={response.id} wrap={false}>
                          <Text style={styles.subTitle3}>
                            Q: {question?.question || 'Question not found'}
                          </Text>
                          <Text style={styles.response}>{response.text_response}</Text>
                        </View>
                      );
                    })}
                  <Text style={styles.subTitle1a}>Letter Writing</Text>
                    {letterResponses.map((response, index) => {
                      const question = englishLetterWriting.find(q => q.id === response.question_id);
                      return (
                        <View key={response.id} wrap={false}>
                          <Text style={styles.subTitle3}>
                            Q: {question?.question || 'Question not found'}
                          </Text>
                          <Text style={styles.response}>{response.text_response}</Text>
                        </View>
                      );
                    })}
                  <Text style={styles.subTitle1a}>Composition Question</Text>
                    {compositionResponses.map((response, index) => {
                      const question = englishWriting.find(q => q.id === response.question_id);
                      return (
                        <View key={response.id} wrap={false}>
                          <Text style={styles.subTitle3}>
                            Q{index + 1}: {question?.question || 'Question not found'}
                          </Text>
                          <View style={styles.columnContainer}>
                            {question?.text_options?.map(option => (
                              <Text style={styles.column1}>{option}</Text>
                            ))}
                          </View>
                          <Text style={styles.response}>{response.text_response}</Text>
                        </View>
                      );
                    })}
                </View>
                {/* Footer */}
                <Text style={styles.footer} render={({ pageNumber, totalPages }) => (
                  `Page ${pageNumber} of ${totalPages}`
                )} fixed />
            </Page>
            </Document>
        </PDFViewer>
      </>
    )

};

// export default CandidatePDF;
