import React, { useEffect, useState } from 'react';
import ETACalulationQuestions from './data/etaCalculation.json';
import AverageIncomeQuestions from './data/mathAverageIncome.json';
import AverageEstimation from './data/mathAverageEstimation.json';
import AverageMistake from './data/mathAverageMistake.json';
import WeightGuess from './data/mathWeightGuess.json';
import logicalRelationship from './data/logicalReasonRelationship.json';
import englishGrammer from './data/englishGrammer.json';
import dataInterpretationQuestions from './data/dataInterpretation.json';
import logicImageSequence from './data/logicalReasonImage.json';
import psychometricQuestionsFresher from './data/psychometricQuestionsFresher.json';
import englishWritingQuestions from './data/englishWriting.json';
import englishLetterWriting from './data/englishLetterWriting.json';
import englishComprehension from './data/englishComprehension.json';

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);
document.psych_index_status = false;

const OnlineTestJson = ({ candidateId }) => {
  console.log('candidate id is ', candidateId);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  // eslint-disable-next-line
  const [isCancelled, setIsCancelled] = useState(false);
  // const [isPsychQues, setIsPsychQues] = useState(false);
  const [textResponse, setTextResponse] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
    // eslint-disable-next-line
  const [currentSet, setCurrentSet] = useState(null);  // Added setCurrentSet definition
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const getRandomQuestions = (questions, num) => {
      const shuffled = questions.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    };

    const processQuestionSet = (questionSet, num) => {
      return getRandomQuestions(questionSet, num).flatMap(set => set.questions ? set.questions : [set]);
    };

    const selectedETACalulationQuestions = processQuestionSet(ETACalulationQuestions, 1);
    const selectedAverageIncomeQuestions = processQuestionSet(AverageIncomeQuestions, 1);
    const selectedAverageEstimation1 = processQuestionSet(AverageEstimation, 1);
    const selectedAverageEstimation2 = processQuestionSet(AverageEstimation, 1);
    const selectedAverageMistake1 = processQuestionSet(AverageMistake, 1);
    const selectedAverageMistake2 = processQuestionSet(AverageMistake, 1);
    const selectedWeightGuess1 = processQuestionSet(WeightGuess, 1);
    const selectedWeightGuess2 = processQuestionSet(WeightGuess, 1);
    const selectedLogicalRelationship = processQuestionSet(logicalRelationship, 2);
    const selectedEnglishGrammer = processQuestionSet(englishGrammer, 4);
    const selectedDataInterpretationQuestions = getRandomQuestions(dataInterpretationQuestions, 1);
    const selectedEnglishComprehension = getRandomQuestions(englishComprehension, 1);
    const selectedLogicImageSequence = processQuestionSet(logicImageSequence, 3);
    const selectedPsychometricQuestions = getRandomQuestions(psychometricQuestionsFresher, 1);
    const selectedEnglishWritingQuestions = getRandomQuestions(englishWritingQuestions, 1);
    const selectedenglishLetterWriting = getRandomQuestions(englishLetterWriting, 1);

    const combinedQuestions = [
      ...selectedAverageIncomeQuestions,
      ...selectedAverageEstimation1,
      ...selectedAverageMistake1,
      ...selectedWeightGuess1,
      ...selectedAverageEstimation2,
      ...selectedETACalulationQuestions,
      ...selectedWeightGuess2,
      ...selectedAverageMistake2,
      ...selectedLogicalRelationship,
      ...selectedLogicImageSequence,
      ...selectedDataInterpretationQuestions.flatMap(set => set.questions),
      ...selectedEnglishGrammer,
      ...selectedEnglishComprehension.flatMap(set => set.questions),
      ...selectedenglishLetterWriting,
      ...selectedPsychometricQuestions,
      ...selectedEnglishWritingQuestions,
    ];

    setQuestions(combinedQuestions);
    setCurrentSet(selectedDataInterpretationQuestions[0]);

    document.addEventListener('selectstart', (e) => e.preventDefault());
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    const handleVisibilityChange = () => {
      // console.log(isPsychQues)
      // console.log(document.psych_index_status)
      // controling the tab changeover

      if (document.hidden) {
        if(document.psych_index_status === false){
          alert('You have changed the tab or minimized the browser. The test will now end.');
          setIsCancelled(true);  
        }
        
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('selectstart', (e) => e.preventDefault());
      document.removeEventListener('contextmenu', (e) => e.preventDefault());
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };

  }, []);

  useEffect(() => {
    // Create an interval that updates every second
    const interval = setInterval(() => {
      setTimer(prevTime => prevTime + 1);  // Increment the timer by 1 every second
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  

  const formatTime = () => {
    const hours = Math.floor(timer / 3600);
    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }




  const handleNextQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];
    // document.question = currentQuestion
    const psych_index = currentQuestion.id.indexOf("Psych")
    if(psych_index===-1){
      document.psych_index_status = false;
    }
    else{
      document.psych_index_status = true;
    }
   
    const response = {
      questionId: currentQuestion.id,
      // question: currentQuestion.question,
      // options: currentQuestion.options,
      correctAnswer: currentQuestion.correct_answer,
      selectedOption,
      textResponse,
      weightage: currentQuestion.weightage || 0  // Ensure weightage is included and defaults to 0 if not present
    };
  
    setResponses([...responses, response]);
  
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption('');
      setTextResponse('');
      setCharCount(0);
      setWordCount(0);
    } else {
      submitResponses([...responses, response]);
      updateTimeTracker(candidateId, timer);
    }
  };
  // Submit responses
  const submitResponses = async (responses) => {
    const formattedResponses = responses.map(response => ({
      candidate_id: candidateId,
      question_id: response.questionId,
      question: response.question,
      options: JSON.stringify(response.options),
      selected_option: response.selectedOption,
      correct_answer: response.correctAnswer,
      text_response: response.textResponse,
      marks_scored: response.selectedOption === response.correctAnswer ? response.weightage : 0,
    }));
  
    console.log('Data that is to be submitted is -', formattedResponses);
    console.log('candidate id is ', candidateId);
  
    try {
      const { data, error } = await supabase
        .from('responses')
        .insert(formattedResponses);
  
      if (error) {
        throw error;
      }
  
      console.log('Responses submitted:', data);
      alert('Test submitted successfully!');
      setIsCompleted(true);
    } catch (error) {
      console.error('Error submitting test:', error);
      alert('There was an error submitting the test. Please try again.');
    }
  };
  

  const handleTextInputChange = (e) => {
    const value = e.target.value.slice(0, 5000);
    setTextResponse(value);
    setCharCount(value.length);
    setWordCount(value.trim().split(/\s+/).length);
  };

  // for inserting the time taken to complete the test
  const updateTimeTracker = async (candidateId, timer) => {
    const timeTaken = Math.ceil(timer / 60); // Convert seconds to minutes and round up
  
    const { data, error } = await supabase
      .from('time_tracker')
      .insert([
        { candidate_id: candidateId, time_taken: timeTaken }
      ]);
  
    if (error) {
      console.error('Error updating time tracker:', error);
    } else {
      console.log('Time tracker updated successfully:', data);
    }
  };
  

  if (isCompleted) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4 text-center">Test Completed</h2>
          <p className="text-center">
            Thank you for completing the test. Your responses have been recorded. Your candidate ID is <strong>{candidateId}</strong>.
          </p>
        </div>
      </div>
    );
  }
  if (isCancelled) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4 text-center">Test interrupted due to change of browser</h2>
          <p className="text-center">
            You have changed or minimised the browser, hence your test is cancelled. Please contact admin or support.
          </p>
        </div>
      </div>
    );
  }

  if (questions.length === 0) {
    return <div>Loading questions...</div>;
  }

  // timer change to red after 90 mins

  const bgColor = timer >= 7200 ? 'bg-red-500' : 'bg-green-400';

  const currentQuestion = questions[currentQuestionIndex];

  const isTextOptions = currentQuestion.text_options && currentQuestion.text_options.length > 0;
  const currentQuestionSet = dataInterpretationQuestions.find(set => set.questions.some(q => q.id === currentQuestion.id));
  const isDataInterpretationQuestion = currentQuestionSet !== undefined;
  const currentQuestionSetEngComprehension = englishComprehension.find(set => set.questions.some(q => q.id === currentQuestion.id));
  // const isEngComprehension = currentQuestionSet !== undefined;
  const isLogicalReasoningQuestion = logicImageSequence.some(q => q.id === currentQuestion.id);
  const isPsychometricQuestion = psychometricQuestionsFresher.some(q => q.id === currentQuestion.id && q.type === 'text');
  const isletterWriting = englishLetterWriting.some(q => q.id === currentQuestion.id && q.type === 'text');
  const isComposition = englishWritingQuestions.some(q => q.id === currentQuestion.id && q.type === 'text');

  

  return (
    <>
    <div className="min-h-screen flex items-center justify-center" style={{background: "#454545"}}>
    <div className={`absolute top-4 right-4 ${bgColor} p-2 rounded text-white text-xl`}>
          Time: {formatTime()}
    </div>
      <div className="bg-white p-8 rounded shadow-md w-2/3" style={{ userSelect: 'none' }}>
          
        {isDataInterpretationQuestion && (
          <div className="mb-6">
            <p className="font-medium text-lg">{currentQuestionSet.information.text}</p>
            <div className='flex justify-center'>
              {currentQuestionSet.information.image && (
                <img className='di_image' src={currentQuestionSet.information.image} alt="Contact Admin" />
              )}
            </div>
          </div>
        )}
        {currentQuestionSetEngComprehension && (
          <div className="mb-6">
            <p className='font-medium text-2xl mb-4 text-center bg-blue-500 p-2 text-white'>Read the following Paragraph and answer the questions that are given.</p>
              <p className="font-medium text-lg" style={{color:'#757575'}}>{currentQuestionSetEngComprehension.information.text}</p>
              {currentQuestionSetEngComprehension.information.image && (
                  <img className='di_image' src={currentQuestionSetEngComprehension.information.image} alt="Contact Admin" />
              )}
          </div>
      )}


        {isLogicalReasoningQuestion && currentQuestion.image && (
          <div className="mb-6 flex justify-center">
            <img className='di_image' src={currentQuestion.image} alt="Contact Admin" />
          </div>
        )}
        {isPsychometricQuestion && (
          <div className="mb-6 subtext">
            <p>This is a psychometric question designed to gain insight into your personal perspectives and interactions. There are no right or wrong answers. Please respond as honestly as you can to the question below in about 100 words.</p>
          </div>
        )}
        {isletterWriting && (
          <div className="mb-6 subtext">
            <p>Consider the scenario provided below. Based on the details, draft a mail (only body text), in about 500 words to address the issue described. Your response should demonstrate your ability to communicate effectively, maintain professionalism, and adapt your tone appropriately to the context. Focus on expressing your concerns, requests, or feedback clearly and constructively, while also considering the impact of your words on the recipient.</p>
          </div>
        )}
        <div className="mb-6">
          <p className="font-medium text-lg">{currentQuestion.question}</p>
          {isTextOptions && (
            <div className="grid grid-cols-2 gap-4 mt-4 font-medium text-lg">
            {currentQuestion.text_options.map((option, index) => (
              <div key={index} className="relative pl-6">
                {/* Using pseudo-element for bullet point */}
                <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
                  <span className="block h-2 w-2 bg-black rounded-full"></span>
                </div>
                {option}
              </div>
            ))}
          </div>
          )}
          {currentQuestion.options ? (
            <ul className="list-disc list-inside m-7 mt-10">
              {currentQuestion.options.map((option, index) => (
                <li key={index}>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name={`question-${currentQuestion.id}`}
                      value={option}
                      checked={selectedOption === option}
                      onChange={() => setSelectedOption(option)}
                      className="form-radio"
                    />
                    <span className="ml-2">{option}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : isPsychometricQuestion ? (
            <>
              <textarea
                value={textResponse}
                onChange={handleTextInputChange}
                rows="4"
                className="w-full border rounded p-2 mt-9"
                placeholder="Type your response here..."
                maxLength="500"
              />
              <div className="text-left text-sm mt-2">
                <span>{charCount} characters, {wordCount} words</span>
                <p className='text-right text-red-600 '>{500-charCount} characters remaining</p>
              </div>
            </>
          ) : isletterWriting || isComposition? (
            <>
              <textarea
                value={textResponse}
                onChange={handleTextInputChange}
                rows="4"
                className="w-full border rounded p-2 mt-9"
                placeholder="Type your response here..."
                maxLength="5000"
              />
              <div className="text-left text-sm mt-2">
                <span>{charCount} characters, {wordCount} words</span>
                <p className='text-right text-red-600 '>{5000-charCount} characters remaining</p>
              </div>
            </>
          ) : null}
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleNextQuestion}
          >
            {currentQuestionIndex < questions.length - 1 ? 'Next' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default OnlineTestJson;
