import React from 'react';

const CandidateSuccess = ({ candidate, onStartTest }) => {
  // console.log('candidate id is ', candidate.id);
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Registration Successful</h2>
        <p className="text-start">
          Welcome <strong><span style={{ textTransform: 'capitalize' }}>{candidate.name}</span></strong>. <br />
          You have been registered successfully.<br />
        </p>
        <div className='mt-5 flex justify-center'>
        <button onClick={() => onStartTest(candidate.id)} className="bg-green-500 text-white px-6 py-2 rounded">
            Read Instructions
        </button>
        </div>
      </div>
    </div>
  );
};

export default CandidateSuccess;

