import React, { useState } from 'react';
import Modal from 'react-modal';
import { createClient } from '@supabase/supabase-js';
import CandidateDetails from './CandidatesDetails';
import { data } from 'autoprefixer';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);
Modal.setAppElement('#root'); // Assuming your root element has an ID of 'root'




const Dashboard = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [showDetails, setShowDetails] = useState(false);  // To control popup visibility
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);  // To store selected candidate ID
  // const [selectedCandidateName, setSelectedCandidateName] = useState(null);

  

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxHeight: '90vh',
      overflow: 'auto'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'  // Dim the background
    }
  };
  

  const searchCandidates = async () => {
    let query = supabase.from('candidates').select('*');
    if (name) {
      query = query.ilike('name', `%${name}%`);
    }
    if (phone) {
      query = query.ilike('phone', phone);
    }
    const { data, error } = await query;
    if (error) {
      console.error('Error fetching candidates:', error);
      return;
    }
    setCandidates(data);
    setName('');
    setPhone('');
  };

  const handleShowDetails = (id) => {
    setSelectedCandidateId(id);  // Set the candidate ID
    // setSelectedCandidateName(data.name);
    setShowDetails(true);  // Open the popup
  };

  const handleCloseDetails = () => {
    setShowDetails(false);  // Close the popup
  };


  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive shadow rounded border">
            <div className="flashing-text align-center w-full">
              <div className='mt-4 text-center py-1 w-100' >
                <h1 className='font-bold text-3xl mb-2'>Welcome to the HR Admin portal.</h1>
                <h2 className='text-white mb-4'>Search for the candidate with the mobile number or name</h2>
                <div className='w-full flex align-middle justify-evenly'>
                  <div className='text-white text-start'>
                    <label className="block font-medium my-3">Candidate Name</label>
                    <input
                      type="text"
                      placeholder="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className='rounded-full text-black px-6 text-center'
                    />
                  </div>
                  <div className='text-white text-start'>
                    <label className="block font-medium my-3">Candidate Phone Number</label>
                    <input
                      type="text"
                      placeholder="phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className='rounded-full text-black px-6 text-center'
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button type="submit" onClick={searchCandidates} className="bg-blue-500 text-white px-4 py-2 rounded-full">
                    Search
                  </button>
                </div>
              </div>
            </div>
            <table className="w-full m-auto mt-6">
              <thead>
                <tr className='border-t-2'>
                  <th className="border-b-2 border-r-2 border-l-2 p-3" style={{minWidth:'50px'}}>#</th>
                  <th className="border-b-2 border-r-2 p-3" style={{minWidth:'100px'}}>Candidate ID</th>
                  <th className="border-b-2 border-r-2 p-3" style={{minWidth:'100px'}}>Name</th>
                  <th className="border-b-2 border-r-2 p-3" style={{minWidth:'100px'}}>Email</th>
                  <th className="border-b-2 border-r-2 p-3" style={{minWidth:'100px'}}>Phone</th>
                  <th className="border-b-2 border-r-2 p-3" style={{minWidth:'100px'}}>Referal</th>
                  <th className="border-b-2 border-r-2 p-3" style={{minWidth:'100px'}}>Select</th>
                </tr>
              </thead>
              <tbody className='border-b-2'>
                {candidates.map((candidate, index) => (
                  <tr key={candidate.id}>
                    <th className="p-3 border-r-2 border-l-2">{index + 1}</th>
                    <td className="p-3 border-r-2">{candidate.id}</td>
                    <td className="p-3 border-r-2">{candidate.name}</td>
                    <td className="p-3 border-r-2">{candidate.email}</td>
                    <td className="p-3 border-r-2">{candidate.phone}</td>
                    <td className="p-3 border-r-2">{candidate.referal}</td>
                    <td className="p-3 border-r-2">
                      <button className="bg-transparent border-none cursor-pointer text-center" onClick={() => handleShowDetails(candidate.id)}>
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showDetails && (
        <Modal
          isOpen={showDetails}
          onRequestClose={handleCloseDetails}
          // className={customStyles}
          overlayClassName="modal-overlay"
          contentLabel="Candidate Details"
        >
          <CandidateDetails candidateId={selectedCandidateId} onClose={handleCloseDetails} />
        </Modal>
      )}

    </>
  );
};

export default Dashboard;
