import React from 'react';
import { Page, Text, Document, StyleSheet } from '@react-pdf/renderer';

// Stylesheet for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30
  },
  section: {
    margin: 10,
    padding: 5
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  }
});

// PDF Document Component
const CandidatePDF = ({ candidate }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Candidate Report: {candidate.name}</Text>
      <Text>Details here...</Text>
    </Page>
  </Document>
);

// Main component for display and print
const CandidateReport = ({ candidate }) => {
  return (
    <div>
        dummy test
      {/* <h1>Candidate Report: {candidate.name}</h1>
      <p>More details here...</p>
      <button onClick={() => window.print()}>Print Report</button>
      PDF download link or button can be added here using CandidatePDF */}
    </div>
  );
};

export default CandidateReport;
