import React, { useState, useEffect } from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import { CandidatePDFDocument} from './CandidatePDF';
import { PDFViewer } from '@react-pdf/renderer';
import ReactModal from 'react-modal';
import LoadingModal from './LoadingModal';

import psychometricQuestionsFresher from './data/psychometricQuestionsFresher.json';
import englishLetterWriting from './data/englishLetterWriting.json';
import englishWriting from './data/englishWriting.json';

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const CandidateDetails = ({ candidateId, onClose, name }) => {
  const [candidate, setCandidate] = useState(null);
  const [responses, setResponses] = useState([]);
  const [totalMarks, setTotalMarks] = useState({ Math: 0, Logical: 0, Data: 0, English: 0, Total: 0 });
  const [showPDF, setShowPDF] = useState(false); // State to toggle PDF viewer
  const [showPDFModal, setShowPDFModal] = useState(false);
  console.log ('candidate name is ', name);
  console.log ('candidate id is ', candidateId);

  useEffect(() => {
    // Fetch data logic
  }, [candidateId]);


  const togglePDFModal = () => {
    setShowPDFModal(!showPDFModal);
  };

    

  // console.log('Candidate id details fetched is ', candidateId);

  useEffect(() => {
    const fetchDetails = async () => {
      const { data: candidateData, error: candidateError } = await supabase
        .from('candidates')
        .select('*')
        .eq('id', candidateId)
        .single();
      
      if (candidateError) console.error(candidateError);
      setCandidate(candidateData);

      const { data: responseData, error: responseError } = await supabase
        .from('responses')
        .select('*')
        .eq('candidate_id', candidateId);

      if (responseError) console.error(responseError);
      setResponses(responseData);
      
      // Calculate marks based on q_section
      const marks = responseData.reduce((acc, curr) => {
        const section = curr.q_section;
        switch (section) {
          case 'Math':
            acc.Math = (acc.Math || 0) + curr.marks_scored;
            break;
          case 'LR':
            acc.Logical = (acc.Logical || 0) + curr.marks_scored;
            break;
          case 'data':
            acc.Data = (acc.Data || 0) + curr.marks_scored;
            break;
          case 'Eng':
            acc.English = (acc.English || 0) + curr.marks_scored;
            break;
          default:
            // Optionally handle unexpected sections
            break;
        }
        acc.Total += curr.marks_scored;
        return acc;
      }, { Math: 0, Logical: 0, Data: 0, English: 0, Total: 0 });


      setTotalMarks(marks);
    };

    fetchDetails();
  }, [candidateId]);

  if (!candidate) return <div>Loading...</div>;

  // if (process.env.NODE_ENV === 'development') {
  //   return (
  //     <CandidatePDFDocument candidate={candidate} totalMarks={totalMarks} responses={responses} />
  //   );
  // }


  return (
    <>
    <div className="modal-content mt-10 mx-20" id='modal-content'>
        <div className="relative">
            <div className="absolute right-0 p-2">
                <button onClick={() => window.print()} className="text-blue-600 hover:text-blue-800 p-2">
                <i className="fas fa-print"></i>
                </button>
                <button onClick={togglePDFModal} className="text-blue-600 hover:text-blue-800 p-2">
                  <i className="fas fa-download"></i> {showPDFModal ? 'Hide Report' : 'View Report'}
                </button>
                <button onClick={onClose} className="text-blue-600 hover:text-blue-800 p-2">
                <i className="fas fa-times"></i>
                </button>
            </div>
        </div>

      <h1 className='text-center text-4xl pb-3 font-bold text-brand-blue'>Blue Water Trade Winds</h1>
      <h2 className='text-center text-3xl pb-3 font-medium text-brand-yellow'>Voyage Performance Analyst</h2>
      <h3 className='text-center text-2xl pb-6 text-brand-black font-medium'>Online Test Report</h3>
      <div id='top-level-info'>
        <div className='grid grid-cols-4 gap-4 text-xl font-normal py-3'>
          <div>Candidate Name:</div>
          <div className='font-semibold underline underline-offset-4'>{candidate.name}</div>
          <div>Candidate Email:</div>
          <div className='font-semibold underline underline-offset-4'>{candidate.email}</div>
        </div>
        <div className='grid grid-cols-4 gap-4 text-xl font-normal py-3'>
          <div>Referral Emp:</div>
          <div className='font-semibold underline underline-offset-4'>{candidate.referal}</div>
          <div>Candidate Phone:</div>
          <div className='font-semibold underline underline-offset-4'>{candidate.phone}</div>
        </div>
        <hr className='my-3' />
      </div>
      <div id='marks-info'>
      <h3 className='text-start text-2xl pt-6 pb-3 font-medium text-blue-500 '>Multiple Choice Questions Scorecard</h3>
        <div className='grid grid-cols-4 gap-4 text-xl font-normal py-3'>
          <div>Math:</div>
          <div className='font-semibold underline underline-offset-4'>{totalMarks.Math}</div>
          <div>Logical Reasoning:</div>
          <div className='font-semibold underline underline-offset-4'>{totalMarks.Logical}</div>
        </div>
        <div className='grid grid-cols-4 gap-4 text-xl font-normal py-3'>
          <div>Data Interpretation:</div>
          <div className='font-semibold underline underline-offset-4'>{totalMarks.Data}</div>
          <div>English Grammer & Comprehension:</div>
          <div className='font-semibold underline underline-offset-4'>{totalMarks.English}</div>
        </div>
        <div className='grid grid-cols-2 gap-4 text-xl font-normal py-3'>
          <div>Total Marks Scored:</div>
          <div className='font-semibold underline underline-offset-4'>{totalMarks.Total}</div>
        </div>
        <hr className='my-5' />
      </div>
      <div>
      
      </div>
      <div id='text-response-info'>
      <h3 className='text-start text-2xl pt-6 pb-3 font-medium text-blue-500 '>English Writing Responses</h3>
        {/* // for psychometric questions */}
          {responses.filter(r => r.question_id.startsWith('Psych')).map(response => {
          const question = psychometricQuestionsFresher.find(q => q.id === response.question_id);
          return (
              <div key={response.id}>
              <h2 className='text-xl' style={{color:'#757575'}}><span className='font-bold'>Q : - </span>{question?.question || 'Question not found'}</h2>
              <p className='ml-12 my-2 text-xl box-border p-4 border-2'>{response.text_response}</p>
              </div>
          );
          })}
          <hr className='my-5' />
        {/* // for letter writing section */}
          {responses.filter(r => r.question_id.startsWith('Eng_LetterWriti')).map(response => {
          const question = englishLetterWriting.find(q => q.id === response.question_id);
          return (
              <div key={response.id}>
              <h2 className='text-xl' style={{color:'#757575'}}><span className='font-bold'>Q : - </span>{question?.question || 'Question not found'}</h2>
              <p className='ml-12 my-2 text-xl box-border p-4 border-2'>{response.text_response}</p>
              </div>
          );
          })}
          <hr className='my-5' />
        {/* // for english writing section */}
          {responses.filter(r => r.question_id.startsWith('Eng_Composition')).map(response => {
          const question = englishWriting.find(q => q.id === response.question_id);
          return (
              <div key={response.id}>
              <h2 className='text-xl' style={{color:'#757575'}}><span className='font-bold'>Q : - </span><span className='ml-10'>Write a passage in about 500 words on any ONE of the topics listed below. Feel free to use Google or any other search engine to gather information about the topic, and then summarize your findings in your own words. But do not use Chat GPT or any other generative AI tools to frame your answer. This question aims to evaluate your research skills and your ability to articulate a concise summary.</span></h2>
              <h2 className='text-xl font-bold' style={{color:'#757575'}}>Options : - </h2>
              <div className="grid grid-cols-4 gap-4">
                {question?.text_options?.map(option => (
                  <h2 className="text-xl text-center text-gray-600">{option}</h2>
                ))}
              </div>
              <p className='ml-12 my-2 text-xl box-border p-4 border-2'>{response.text_response}</p>
              </div>
          );
          })}
      </div>
    </div>
    </>
  );
};

export default CandidateDetails;
