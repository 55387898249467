import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CandidateForm from './CandidateForm';
import CandidateSuccess from './CandidateSuccess';
import TestGuidelines from './TestGuideLines';
import OnlineTestJson from './OnlineTestJson';
import logo from '../Images/BlueWater_logo.jpg';

const Home = () => {
    const navigate = useNavigate();

    const [isRegistered, setIsRegistered] = useState(false);
    const [candidate, setCandidate] = useState(null);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const [showTest, setShowTest] = useState(false);

    // Handles the next step after registration
    const handleNext = (candidateData) => {
        setCandidate(candidateData);
        setIsRegistered(true);
        // After registration, show CandidateSuccess
        // Remove or comment out setShowTest(true);
    };
    
    // Handler to move from CandidateSuccess to TestGuidelines or directly to test
    const handleStartTest = () => {
        setShowGuidelines(true); // Show guidelines if needed, otherwise directly start test
        // Comment this if guidelines should be shown
        // startTest(); 
    };

    // Function to start the actual test
    const startTest = () => {
        setShowTest(true);
    };

    return (
        <>
            <div>
                <div className="grid grid-cols-10 gap-4">
                    <div className="col-span-3 flex items-center justify-center">
                        <img src={logo} alt="company logo" />
                    </div>
                    <div className="col-span-5 flex flex-col items-center justify-center">
                        <h1 className="text-center font-bold title">Blue Water <span>Trade Winds</span></h1>
                        <h1 className="text-center font-bold subtitle">Voyage Performance Analyst <br/><span>Online Test Portal</span></h1>
                    </div>
                    <div className="col-span-2 flex items-center justify-center">
                        {/* Admin Login Button */}
                        <button
                            type="button"
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                            onClick={() => navigate('/login')}
                        >
                            Admin Login
                        </button>
                    </div>
                </div>
                {!isRegistered && <CandidateForm onNext={handleNext} />}
                {isRegistered && !showGuidelines && !showTest && (
                    <CandidateSuccess candidate={candidate} onStartTest={handleStartTest} />
                )}
                {showGuidelines && !showTest && (
                    <TestGuidelines onStartTest={startTest} />
                )}
                {showTest && <OnlineTestJson candidateId={candidate?.id} />}
            </div>
        </>
    );
};

export default Home;
