// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import MathTest from './components/OnlineTestJson';
import './App.css';
import Dashboard from './components/Dashboard';
import CandidateDetails from './components/CandidatesDetails';
import CandidateReport from './components/CandidateReport';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/online_test' element={<MathTest/>} />
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path="/candidate/:id" element={<CandidateDetails />} />
        <Route path='/candidate-report' element={<CandidateReport/>} />
      </Routes>
    </Router>
  );
}

export default App;
