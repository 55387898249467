import React from 'react';

const TestGuidelines = ({ onStartTest }) => {
  // console.log('started reading instructions by ');
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full m-20">
        <h2 className="text-4xl font-bold mb-4 text-center">Online Test Instructions</h2>
        <div className="text-xl">
        <p className="text-start mb-2">
        Welcome to the online aptitude test. This test is designed to assess your abilities in various areas. Please read the instructions carefully before beginning the test.
        </p>
        <div className="ml-6 text-justify">
          <ul>
            <li className='m-2'>
              <strong className='pr-2'>Mathematics</strong> Testing fundamental arithmetic skills typically covered in school curricula. A total of <strong>8 questions</strong> are included in this section.
            </li>
            <li className='m-2'>
              <strong className='pr-2'>Data Interpretation</strong> Evaluating your ability to analyze and interpret data presented in charts, graphs, or tables, crucial for effective decision-making. <strong>1</strong> question set with <strong>3 sub-questions</strong> .
            </li>
            <li className='m-2'>
              <strong className='pr-2'>Logical Reasoning</strong> Challenges your problem-solving and critical thinking with puzzles and logical sequences similar to those found in graduate-level competitive exams. A total of <strong>5 questions</strong> will be included in this section.
            </li>
            <li className='m-2'>
              <strong className='pr-2'>English</strong> A check for your proficiency in Business-Level Communication. This section will include grammar section, a comprehension and letter writing.
              {/* <ul className='text-l ml-6'>
                <li className='ml-6'>
                  <strong className=''>English Grammar:- </strong>Assessing knowledge of grammar is required for clear and professional business interactions. A total of 4 questions will be included in this section.
                </li>
                <li className='ml-6'>
                  <strong className=''>English Comprehension:- </strong>Measures your ability to comprehend and interpret business-related written English. Total 1 question set with 3 sub-questions..
                </li>
                <li className='ml-6'>
                  <strong className=''>English Composition:- </strong>Evaluate your capability to express ideas clearly and concisely in written English, essential for business correspondence and reports. A total of 2 questions will be there in this section.
                </li>
              </ul> */}
            </li>
            <li className='m-2'>
              <strong className='pr-2'>Psychometric </strong> This section involves activities to assess personality traits and cognitive abilities, focusing on how you might fit into a team or handle workplace scenarios. There is no right or wrong answers. Be honest in answering this question.
            </li>
          </ul>
        </div>
        <div>
          <strong className='text-blue-700'>Terminology</strong>
          <div className='ml-6 text-justify'>
            <ul>
              <li className='m-2'>
                <strong className='pr-2'>ETA (Estimated Time of Arrival) </strong> This term refers to the expected arrival time of a ship, calculated based on its travel speed and the remaining distance.
              </li>
              <li className='m-2'>
                <strong className='pr-2'>Nautical Miles</strong> A unit of measurement used at sea, equivalent to distances measured in kilometres on land.
              </li>
              <li className='m-2'>
                <strong className='pr-2'>Knots</strong> A unit of speed is used to denote the number of nautical miles travelled by a ship in an hour.
              </li>
            </ul>
          </div>
        <div className='text-center my-10 '>
          <strong className='text-blue-700'>Important Instructions</strong>
          <div className='ml-6 text-justify'>
            <ul>
              <li className='m-2'>
                You are <strong>allowed</strong> to use calculator during the exam. You can use calculator in your phone.
              </li>
              <li className='m-2'>
                You are <strong>not allowed</strong> to use any online search tool to answer the questions during the exam. Any change of tab will end the test without saving your results. You can use internet in one of the question which will CLEARLY mention this and allow you to change the tab.
              </li>
            </ul>
          </div>
        </div>
        <div className='text-red-600 flashing-text'>
          <strong>Note :- </strong> Once you answer a question and click the 'Next' button, you will<u style={{fontWeight: '900'}}> NOT </u>be able to return to the previous question. Hence please review your answers carefully before proceeding.
        </div>

        </div>
          {/* Add more guidelines as needed */}
       
        <div className='mt-5 flex justify-center'>
          <button
            type="button"
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={onStartTest}
          >
            Start Test
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TestGuidelines;
